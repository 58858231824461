.bb-wishlist-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.875em 0 0.9375em;
}

.bb-wishlist-actions-import-button {
  background-color: #E0E1E2 !important;
  color: rgba(0, 0, 0, 0.6) !important;
}

.bb-wishlist-actions-import-button:hover {
  background-color: #CACBCD !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.bb-wishlist-item {
  display: grid;
  align-items: center;
  padding: 0.625em;
  border-bottom: 1px solid #e9ecef;
  font-weight: 900;
}
@media screen and (max-width: 768px) {
  .bb-wishlist-item {
    grid-template-areas: "image name select" "image price price" "variant variant variant" "quantity quantity actions";
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-column-gap: 0.625em;
    grid-row-gap: 0.9375em;
  }
  .bb-wishlist-item:first-of-type {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .bb-wishlist-item {
    grid-template-areas: "select image name price quantity actions" "select image variant price quantity actions";
    grid-template-columns: 50px 100px 5fr 1fr 1fr 1fr;
    grid-template-rows: 1fr auto;
    grid-column-gap: 1.25em;
  }
}
.bb-wishlist-item-select {
  grid-area: select;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bb-wishlist-item-image {
  grid-area: image;
  width: 75%;
  height: 75%;
}
.bb-wishlist-item-name {
  grid-area: name;
  font-size: 1.2em;
  font-weight: bold;
}
.bb-wishlist-item-variant {
  grid-area: variant;
}
.bb-wishlist-item-price {
  grid-area: price;
  padding-right: 1.25em;
}
@media screen and (min-width: 768px) {
  .bb-wishlist-item-price {
    text-align: right;
  }
}
.bb-wishlist-item-quantity {
  grid-area: quantity;
  align-items: center;
}
.bb-wishlist-item-actions {
  grid-area: actions;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bb-wishlist-variant {
  display: grid;
  grid-gap: 0.9375em;
  align-items: center;
  font-size: 0.85em;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .bb-wishlist-variant {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 768px) {
  .bb-wishlist-variant {
    grid-template-columns: repeat(3, 1fr);
  }
}
.bb-wishlist-variant-label {
  display: block;
  margin: 0 0 0.3125em 0;
}
.bb-wishlist-variant-select {
  font-weight: bold;
  font-size: 0.85em;
  line-height: 1;
}

.bitbag.copy-modal-initialization {
  display: none;
}
.bitbag.copy-modal-initialized {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 899;
  background-color: rgba(0, 0, 0, 0.25);
}
.bitbag .copy-confirmation-modal {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
}
.bitbag .copy-confirmation-modal__header, .bitbag .copy-confirmation-modal__body, .bitbag .copy-confirmation-modal__confirm {
  padding: 16px 0;
  text-align: center;
}
.bitbag .copy-confirmation-modal__header--title {
  font-size: 24px;
}
.bitbag .copy-confirmation-modal__body {
  font-size: 16px;
}
.bitbag .copy-confirmation-modal__body .form-body {
  display: flex;
}
.bitbag .copy-confirmation-modal__body .form-body--item {
  padding-top: 1rem;
}
.bitbag .copy-confirmation-modal__confirm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.bitbag .copy-confirmation-modal__confirm--cancel, .bitbag .copy-confirmation-modal__confirm--perform {
  cursor: pointer;
  padding: 12px 8px;
  text-align: center;
  min-width: 125px;
  transition: opacity 0.3s ease;
  border-radius: 4px;
  border: none;
  font-weight: 650;
  letter-spacing: 1.25px;
  font-size: 14px;
  text-transform: capitalize;
}
.bitbag .copy-confirmation-modal__confirm--cancel:hover, .bitbag .copy-confirmation-modal__confirm--perform:hover {
  opacity: 0.7;
}
.bitbag .copy-confirmation-modal__confirm--cancel {
  color: #050428;
  border: 1px solid #050428;
  background-color: #ffffff;
}
.bitbag .copy-confirmation-modal__confirm--cancel:focus {
  background-color: #cccccc;
}
.bitbag .copy-confirmation-modal__confirm--perform {
  color: #ffffff;
  border: 1px solid #050428;
  background-color: #050428;
}
.bitbag .copy-confirmation-modal__confirm--perform:focus {
  background-color: #110d85;
}

.copy-confirmation-modal__body--item {
  padding-bottom: 1rem;
  font-size: 16px;
}

.bb-colective-actions.disabled {
  opacity: 0.45;
  pointer-events: none;
}
.bb-colective-actions .wishlist-dropdown-menu__item .wishlist-item--button {
  text-align: left;
  background: #ffffff !important;
  color: rgba(0, 0, 0, 0.95) !important;
  width: 100%;
}
.bb-colective-actions .wishlist-dropdown-menu__item .wishlist-item--button:hover {
  background: #E0E1E2 !important;
}
.bb-colective-actions .wishlist-dropdown-menu__item:hover {
  background: #ffffff !important;
}

.bb-wishlist-header {
  overflow-wrap: anywhere;
}

.bb-wishlist {
  font-size: 16px;
}

@keyframes beep {
  75%, to {
    transform: scale(2);
    opacity: 0;
  }
}
.bb-copy-to-wishlist-sukces {
  animation: beep 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.bb-copy-to-wishlist-faliure {
  animation: beep 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

.bitbag.wishlist-modal-initialization {
  display: none;
}
.bitbag.wishlist-modal-initialized {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.25);
}
.bitbag .wishlist-confirmation-modal {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
}
.bitbag .wishlist-confirmation-modal__header, .bitbag .wishlist-confirmation-modal__body, .bitbag .wishlist-confirmation-modal__confirm {
  padding: 16px 0;
  text-align: center;
  gap: 1rem;
}
.bitbag .wishlist-confirmation-modal__header--title {
  font-size: 24px;
}
.bitbag .wishlist-confirmation-modal__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}
.bitbag .wishlist-confirmation-modal__body--input {
  width: min-content;
  padding-top: 1rem;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  border-color: #85b7d9;
  text-align: left;
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.bitbag .wishlist-confirmation-modal__body--input::placeholder {
  max-height: auto;
}
.bitbag .wishlist-confirmation-modal__body--input.error {
  background-color: #FFF6F6;
  border-color: #E0B4B4;
  color: #9F3A38;
}
.bitbag .wishlist-confirmation-modal__body .validation-error {
  width: min-content;
  white-space: nowrap;
}
.bitbag .wishlist-confirmation-modal__body .form-body {
  display: flex;
}
.bitbag .wishlist-confirmation-modal__confirm {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.bitbag .wishlist-confirmation-modal__confirm--cancel, .bitbag .wishlist-confirmation-modal__confirm--perform {
  cursor: pointer;
  padding: 12px 8px;
  text-align: center;
  min-width: 125px;
  transition: opacity 0.3s ease;
  border-radius: 4px;
  border: none;
  font-weight: 650;
  letter-spacing: 1.25px;
  font-size: 14px;
  text-transform: capitalize;
}
.bitbag .wishlist-confirmation-modal__confirm--cancel:hover, .bitbag .wishlist-confirmation-modal__confirm--perform:hover {
  opacity: 0.7;
}
.bitbag .wishlist-confirmation-modal__confirm--cancel {
  color: #050428;
  border: 1px solid #050428;
  background-color: #ffffff;
}
.bitbag .wishlist-confirmation-modal__confirm--cancel:focus {
  background-color: #cccccc;
}
.bitbag .wishlist-confirmation-modal__confirm--perform {
  color: #ffffff;
  border: 1px solid #050428;
  background-color: #050428;
}
.bitbag .wishlist-confirmation-modal__confirm--perform:focus {
  background-color: #110d85;
}

.wishlist-confirmation-modal__body--item {
  padding-bottom: 1rem;
  font-size: 16px;
}

.wishlist-import {
  margin: auto;
  background-color: #ffffff;
  padding: 1.5rem;
  width: 60%;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.wishlist-import-inputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.wishlist-import-button-wrapper {
  padding-top: 1rem;
}
.wishlist-import-button-wrapper button {
  background: #e0e1e2 none !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.wishlist-import-button-wrapper button:hover {
  background-color: #CACBCD !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.bb-wishlist-list {
  margin: auto;
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}
.bb-wishlist-list-wraper {
  display: flex;
  align-items: center;
}
.bb-wishlist-list-wraper-icon {
  aspect-ratio: 1/1;
  font-size: 1.5em;
  vertical-align: middle;
  margin-right: 2rem;
}
.bb-wishlist-list-wraper-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bb-wishlist-list-wraper-content-name {
  padding-left: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bb-wishlist-list-wraper-content-buttons {
  flex-shrink: 0;
}

@media screen and (max-width: 576px) {
  .bb-wishlist-button > span.text {
    display: none !important;
  }
}

.bb-wishlist-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 12px 24px !important;
  margin: 0 !important;
}
.bb-wishlist-button i {
  width: 35px !important;
}

@media screen and (max-width: 576px) {
  .bb-wishlist-button > span > i.heart {
    margin: unset !important;
  }
}

.bb-add-to-cart-container {
  display: flex;
  gap: 1em;
  padding: 1em 0;
}
.bb-add-to-cart-container > * {
  margin: 0 !important;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .bb-add-to-cart-container {
    flex-direction: column;
  }
}