$bright_color: #ffffff;
$dark_color: #050428;
$transparent_color: rgba(0, 0, 0, 0.25);

.bitbag {
    &.copy-modal-initialization {
        display: none;
    }

    &.copy-modal-initialized {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 899;
        background-color: $transparent_color;
    }

    .copy-confirmation-modal {
        background-color: $bright_color;
        border-radius: 8px;
        padding: 24px;

        &__header,
        &__body,
        &__confirm {
            padding: 16px 0;
            text-align: center;
        }

        &__header {
            &--title {
                font-size: 24px;
            }
        }

        &__body {
            font-size: 16px;
            .form-body {
                display: flex;
                &--item {
                    
                    padding-top: 1rem;
                }
            }
        }

        &__confirm {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            &--cancel,
            &--perform {
                cursor: pointer;
                padding: 12px 8px;
                text-align: center;
                min-width: 125px;
                transition: opacity 0.3s ease;
                border-radius: 4px;
                border: none;
                font-weight: 650;
                letter-spacing: 1.25px;
                font-size: 14px;
                text-transform: capitalize;

                &:hover {
                    opacity: 0.7;
                }
            }

            &--cancel {
                color: $dark_color;
                border: 1px solid $dark_color;
                background-color: $bright_color;

                &:focus {
                    background-color: darken($bright_color, 20%);
                }
            }

            &--perform {
                color: $bright_color;
                border: 1px solid $dark_color;
                background-color: $dark_color;

                &:focus {
                    background-color: lighten($dark_color, 20%);
                }
            }
        }
    }
}

.copy-confirmation-modal__body--item {
    padding-bottom: 1rem;
    font-size: 16px;
}

