prd-lst__title-wrapper:root {
  --color-primary: #c50035;
  --color-primary-hover: #d50039;
  --black-text: #222222;
  --text-light: #8d8d8d;
  --border: #e6e6e6;
  --grey-bg: #fafafa;
}

/*-----------------------------------------------------------*/
/* Template                                                  */
/*-----------------------------------------------------------*/

body {
  -webkit-hyphenate-limit-chars: 6 3 3;
  -webkit-hyphenate-limit-before: 3;
  -webkit-hyphenate-limit-after: 3;
  -webkit-hyphenate-limit-lines: 2;

  -moz-hyphenate-limit-chars: 6 3 3;

  -ms-hyphenate-limit-lines: 2;
  -ms-hyphenate-limit-chars: 6 3 3;

  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  -webkit-font-smoothing: antialiased;

  width: 100%;
}
a {
  color: inherit;
}
svg {
  display: block;
}


/* Utility Classes */

[lang]:not([lang="de"]) [cb-lang-element="de"],
[lang]:not([lang="fr"]) [cb-lang-element="fr"],
[lang]:not([lang="it"]) [cb-lang-element="it"],
[lang]:not([lang="en"]) [cb-lang-element="en"] {
  display: none;
}
.utl__hidden {
  display: none;
}
.utl__inline {
  display: inline;
}
.utl__hyphens {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/* Hide Scrollbar */

[cb-scrollbar-hidden="true"]::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
[cb-scrollbar-hidden="true"] {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body.utl-locked {
  overflow: hidden !important;
}

/* remove webkit styles from Form Fields */

input,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.w-form {
  margin: 0;
}
.w-radio {
  font-weight: inherit;
}
.w-radio:before, .w-radio:after {
  display: none;
}
.w-radio-input:focus{
  outline: none;
}

/* Different CSS for touch devices */

@media (hover: hover) {
}

@media (hover: none) {
}

/* Rich Text */

.utl__rich-text ol li {
  counter-increment: step-counter;
}
.utl__rich-text ol li::before {
  content: counter(step-counter);
  position: absolute;
  left: 0;
  width: 26px;
  height: 26px;
  font-size: 14px;
  color: #111111;
  font-weight: 500;
  background-color: #f3f4f5;
  border-radius: 99px;
  text-align: center;
  line-height: 28px;
}

/* Preloader img */

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -100vw 0;
  }
  100% {
    background-position: 100vw 0;
  }
}

[cb-preloader-element] {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eeeeee;
  background: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(10%, #eeeeee),
          color-stop(30%, #dddddd),
          color-stop(50%, #eeeeee)
  );
  background: -webkit-linear-gradient(left, #eeeeee 10%, #dddddd 30%, #eeeeee 50%);
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 30%, #eeeeee 50%);
  -webkit-background-size: 100% 100%;
  background-size: 100vw 100%;
}

/* Colored selection


::selection {
  background: rgb(197 0 53 / 15%);
  color: black;
}
::-moz-selection {
  background: rgb(67, 83, 255);
  color: black;
}

/* Hides Headings and Links in the Header Symbols when they're empty */

.utl-header__text-wrapper .wf-empty,
.utl-header__text-wrapper *:empty,
.utl-header__wrapper .utl__button[href="#"] {
  display: none;
}

/* hides the last paragraph in rich text elements when they're empty */

.w-richtext p:last-child:empty {
  display: none;
}

/* hides the progressbar from Weglot */

body .wg-progress {
  background-color: transparent;
  box-shadow: none;
  display: none;
}
body .wg-progress-bar {
  background-color: transparent;
  box-shadow: none;
  display: none;
}

.no-click {
  pointer-events: none;
}


/*-----------------------------------------------------------*/
/* Footer                                                    */
/*-----------------------------------------------------------*/


.footer__cookies[fs-cc="manager"] {
  display: block !important;
}
.footer__col:last-child .footer__nav:last-child {
  border-bottom: none;
}
.footer__nav.cb-active .footer__nav__body {
  display: block;
}
.footer__nav.cb-active .footer__nav__icon {
  transform: rotate(270deg);
}
.footer__banner:empty {
  display: none;
}
.footer__bottom .footer__copyright,
.footer__bottom .footer__link,
.footer__bottom__links {
  line-height: 1.2;
}



/*-----------------------------------------------------------*/
/* Templates                                                 */
/*-----------------------------------------------------------*/

/* Modal */

.utl-modal.hidden,
.utl-modal__background {
  pointer-events: none;
}
.utl-modal__background * {
  pointer-events: auto;
}
.utl-modal {
  overflow-y: scroll;
}
.utl-modal.hidden .utl-modal__block {
  -webkit-transform: translate(0, 20px);
  -ms-transform: translate(0, 20px);
  transform: translate(0, 20px);
  opacity: 0;
}
.utl-modal.hidden .utl-modal__background {
  opacity: 0;
}




/*-----------------------------------------------------------*/
/* Layout: Wrappers                                          */
/*-----------------------------------------------------------*/

.lay__section {
  overflow-x: clip;
}

.top-10 {
  padding-top: 10px;
}
.top-15 {
  padding-top: 15px;
}
.top-20 {
  padding-top: 20px;
}
.top-30 {
  padding-top: 30px;
}
.top-40 {
  padding-top: 40px;
}
.top-40 {
  padding-top: 40px;
}
.top-60 {
  padding-top: 60px;
}
.top-80 {
  padding-top: 80px;
}
.top-160 {
  padding-top: 160px;
}

.vertical-10 {
  padding: 10px 0;
}
.vertical-15 {
  padding: 15px 0;
}
.vertical-20 {
  padding: 20px 0;
}
.vertical-30 {
  padding: 30px 0;
}
.vertical-40 {
  padding: 40px 0;
}
.vertical-40 {
  padding: 40px 0;
}
.vertical-60 {
  padding: 60px 0;
}
.vertical-80 {
  padding: 80px 0;
}
.vertical-160 {
  padding: 160px 0;
}

.padding-0 {
  padding: 0px;
}
.padding-20 {
  padding: 20px;
}
.padding-30 {
  padding: 30px;
}


.rows-10 {
  grid-row-gap: 10px;
}
.rows-15 {
  grid-row-gap: 15px;
}
.rows-20 {
  grid-row-gap: 20px;
}
.rows-30 {
  grid-row-gap: 30px;
}
.rows-40 {
  grid-row-gap: 40px;
}
.rows-40 {
  grid-row-gap: 40px;
}
.rows-60 {
  grid-row-gap: 60px;
}
.rows-80 {
  grid-row-gap: 80px;
}
.rows-160 {
  grid-row-gap: 160px;
}


@media (max-width: 479px) {
  .rows-160 {
    grid-row-gap: 80px;
  }
}



.lay__wrapper.height-fill {
  min-height: calc(100svh - 110px);
}





/*-----------------------------------------------------------*/
/* Layout: Boxes                                             */
/*-----------------------------------------------------------*/




@media (hover: hover) {
  .order-box__body:hover .order-box__overlay {
    opacity: 1;
  }
}
.lay__box.active [cb-acc-element=edit] {
  opacity: 1;
  pointer-events: auto;
  display: flex;
  border-top: none;
}
.lay__box.active [cb-acc-element=overview] {
  display: none;
}
.lay__box.inactive > * {
  opacity: 0.1;
}
.lay__box.inactive {
  pointer-events: none;
  border-color: #f0f0f0;
}


/*
.lay__box {
  container-type: inline-size;
}
@container (max-width: 600px) {
  .lay__box__wrapper:not([cb-acc-element=overview]) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  .lay__box [cb-acc-element=overview] .utl__button-text {
    display: none;
  }
  .lay__box [cb-acc-element=overview] .utl__button:not(.small) .utl__icon-embed:first-child {
    margin-left: 0;
  }
  .lay__box [cb-acc-element=open] {
    padding-left: 12px;
    padding-right: 12px;
  }
}
*/




/*-----------------------------------------------------------*/
/* Radio Buttons                                             */
/*-----------------------------------------------------------*/

.lay__row {
  container-type: inline-size;
}

@container (max-width: 600px) {
  .lay__row .utl-ipt__radio__button-wrapper .utl-text-button > *:last-child {
    display: none
  }
}




/*-----------------------------------------------------------*/
/* Swipers                                                   */
/*-----------------------------------------------------------*/


.lay__carousel__wrapper > * {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .lay__carousel__wrapper > * {
    margin-right: 10px;
  }
}

.lay__carousel__nav.cb-swiper-hidden,
.cb-swiper-hidden {
  display: none;
}
.lay__carousel__nav {
  pointer-events: none;
}
.lay__carousel__nav-button {
  opacity: 0;
}
.lay__carousel__nav-button.cb-swiper-disabled {
  cursor: default;
  background-color: white;
  border: 1px solid var(--border);
  color: var(--text-light);
}

@media (hover: hover) {
  .lay__carousel:hover .lay__carousel__nav-button,
  .prd__swiper:hover .lay__carousel__nav-button {
    pointer-events: auto;
    opacity: 1;
  }
}
@media (hover: none) {
  .prd__swiper .lay__carousel__nav-button {
    pointer-events: auto;
    opacity: 1;
  }
  .lay__carousel__nav {
    display: none;
  }
}

.lay__carousel .prd-itm__wrapper,
.lay__carousel.d-4-m-1 .prd-itm__wrapper {
  width: calc(25% - 30px);
}

@media only screen and (max-width: 992px) {
  .lay__carousel .prd-itm__wrapper {
    min-width: 232px;
  }

  .lay__grid.cb-category {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 767px) {
  .lay__carousel .prd-itm__wrapper {
    min-width: 30vw;
  }
}

/* Pagination */

.cb-swiper-pagination {
  width: 10px;
  height: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
}

.cb-swiper-pagination.cb-active {
  background-color: #8d8d8d;
  border-color: #8d8d8d;
}


/* Get the Look â€“ Fullwidth */

.lay__carousel.gtl-hero .lay__carousel__container {
  overflow: visible;
  width: 100%;
}
.lay__carousel.gtl-hero .lay__carousel__wrapper {
  width: 100%;
}
.lay__carousel.gtl-hero .lay__carousel__nav-button {
  color: var(--black-text);
  background-color: white;
  border: 1px solid var(--border);
}
@media (hover: hover) {
  .lay__carousel.gtl-hero .lay__carousel__nav-button:hover {
    background-color: var(--color-primary);
    color: white;
    border: none;
  }
}
.gtl-hero .lay__carousel__wrapper > * {
  margin-right: 0px;
}


.lay__carousel__overflow-wrapper .gtl-hero__container {
  margin-left: 0;
}
.lay__carousel__overflow-wrapper .lay__carousel.gtl-hero {
  margin-top: 0;
}
@media (min-width: 1501px) and (max-width: 2800px) {
  .lay__carousel__overflow-wrapper {
    width: calc(50vw - 710px + 1166px);
  }
  .lay__carousel__overflow-wrapper .lay__carousel__nav {
    padding-right: calc(50vw - 710px + 16px);
  }
}
@media (max-width: 1500px) and (min-width: 992px) {
  .lay__carousel__overflow-wrapper {
    width: calc(100% + 40px)
  }
  .lay__carousel__overflow-wrapper .lay__carousel.gtl-hero .lay__carousel__container {
    max-width: calc(100% - 40px);
  }
}



/*-----------------------------------------------------------*/
/* Update Box                                                */
/*-----------------------------------------------------------*/


.update-box .prd-lst__img-container {
  width: 60px;
  height: 60px;
}
.update-box {
  pointer-events: none;
}
.update-box__wrapper {
  pointer-events: auto;
}
.active-updatebox .update-box__wrapper {
  transform: translateX(0%);
}



/*-----------------------------------------------------------*/
/* Input Fields                                              */
/*-----------------------------------------------------------*/



.utl__select select,
.utl__select input {
  width: 100%;
  height: 42px;
  padding: 0px 16px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  -webkit-appearance: none;
  padding-right: 48px;
}
[cb-amount-element="amount"] input {
  padding-right: 16px;
}

.utl__select input:focus,
.flt-ipt__input:focus {
  outline: none;
}

select {
  width: 100%;
  height: 42px;
  padding: 0px 16px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  -webkit-appearance: none;
}

.prd__select-grid .utl__select input,
.prd__select-grid .utl__select select {
  height: 50px;
}


select:focus {
  outline: none;
}
.utl__select__icon {
  pointer-events: none;
}


.utl-amount select {
  cursor: pointer;
  background-color: transparent;
}
.utl-amount select,
.utl-amount input {
  cursor: pointer;
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
[cb-amount-element="amount"] [cb-amount-element="input"],
[cb-amount-element="amount"].cb-input-mode [cb-amount-element="select"],
[cb-amount-element="amount"].cb-input-mode .utl__select__icon {
  pointer-events: none;
  opacity: 0;
}
[cb-amount-element="amount"] [cb-amount-element="select"],
[cb-amount-element="amount"].cb-input-mode [cb-amount-element="input"] {
  pointer-events: auto;
  opacity: 1;
}

[cb-amount-element="amount"] .amount-input {
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}



[cb-amount-element="amount"] input::-webkit-outer-spin-button,
[cb-amount-element="amount"] input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
[cb-amount-element="amount"] input[type=number] {
  -moz-appearance: textfield;
}




/*-----------------------------------------------------------*/
/* Buttons                                                   */
/*-----------------------------------------------------------*/

.flt-tgs__remove__hover {
  pointer-events: none;
}

.flt-tgs__link:hover .flt-tgs__remove__hover {
  transform: scale(1.0);
  opacity: 1;
}

.utl__button:not(.small) .utl__icon-embed:first-child {
  margin-left:-5px;
}



/*-----------------------------------------------------------*/
/* Accordions                                                */
/*-----------------------------------------------------------*/


.utl-acc.cb-active .utl-acc__icon svg {
  transform: rotate(90deg);
}
.utl-acc__list {
  opacity: 0;
}
.utl-acc.cb-active .utl-acc__list {
  display: block;
  opacity: 1;
}



/*-----------------------------------------------------------*/
/* Filters & Sorting                                         */
/*-----------------------------------------------------------*/


.flt-ipt__group .flt-ipt__list {
  transform: translateY(10px);
  opacity: 0;
  pointer-events: none;
}
.flt-ipt__list.align-right {
  left: auto;
  right: 0;
}
.flt-ipt__group.flt-ipt-active .flt-ipt__list {
  transform: translateY(0px);
  opacity: 1;
  pointer-events: auto;
}
.flt-ipt__group.flt-ipt-active .flt-ipt__toggle {
  background-color: var(--black-text);
  color: white;
  border-color: var(--black-text);
}
.flt-ipt__group.flt-ipt-active .utl__select__icon {
  transform: translateY(-50%) rotate(180deg);
}
.flt-ipt__active-indicator:empty {
  display: none;
}
.flt-ipt__group[cb-filter-state=disabled] .flt-ipt__toggle {
  pointer-events: none;
  opacity: 0.4;
}
.flt-ipt__list__options {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: 10px;
}
.flt-ipt__list__options::-webkit-scrollbar {
  /* display: none; */
}
.flt-srt select {
  padding-right: 24px;
  padding-left: 10px;
  color: #222;
  width: auto;
  height: auto;
  background-color: white;
}
@media (max-width: 380px) {
  .flt-srt__label {
    display: none;
  }
}


.flt-ipt__toggle__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 767px) {
  .flt-ipt__grid {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .flt-ipt__grid::-webkit-scrollbar {
    display: none;
  }
}
@media (max-width: 1220px) {
  .flt-ipt__grid {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}


/* Rangeslider */

.irs-to, .irs-from {
  display: none;
}
.irs--flat .irs-bar {
  background-color: var(--black-text);
  height:1px;
  top: 10px;
}
.irs--flat .irs-handle {
  background-color: white;
  border-radius: 50%;
  border: 1px solid var(--black-text);
  width: 20px;
  height: 20px;
  top: 0px;
}
.irs--flat .irs-handle *,
.irs--flat .irs-handle>i:first-child {
  display: none;
}
.irs--flat .irs-line {
  top: 10px;
  height:1px;
  background-color: var(--border);
}

.flt-ipt__slider-ipt__input::-webkit-outer-spin-button,
.flt-ipt__slider-ipt__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.flt-ipt__slider-ipt__input[type=number] {
  -moz-appearance: textfield;
}




/*-----------------------------------------------------------*/
/* Breadcumbs                                                */
/*-----------------------------------------------------------*/


.breadcumbs-wrapper {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (max-width: 991px) {
  .breadcumbs-wrapper {
    width: calc(100% + 80px);
  }
}
@media (max-width: 767px) {
  .breadcumbs-wrapper {
    width: calc(100% + 40px);
  }
}

.breadcumbs-wrapper::-webkit-scrollbar {
  display: none;
}




/*-----------------------------------------------------------*/
/* Custom CSS                                                */
/*-----------------------------------------------------------*/


@media (hover: hover) {
  .nav-01__bottom__link:hover .nav-bottom__text,
  .nav-02__bottom__link:hover .nav-bottom__text .nav-03__bottom__link:hover .nav-bottom__text {
    color: #4253ff;
  }
}

@media (min-width: 992px) {
  .k-02__right {
    width: calc(50vw + 130px);
  }
}

.page-wrapper {
  overflow-x: clip;
}

.side-nav__list .side-nav__list__item:last-child {
  border-bottom: 0;
}




/*-----------------------------------------------------------*/
/* Navigation: Search                                        */
/*-----------------------------------------------------------*/


.search-dropdown,
.search-overlay {
  pointer-events: none;
}

.search-active .search-overlay {
  opacity: 1;
}
.search-active .search-dropdown {
  pointer-events: auto;
  opacity: 1;
  max-height: 80vh;
}
.search-active .search-dropdown__block {
  opacity: 1;
}
.search-active .search__last__item {
  padding-top: 6px;
  padding-bottom: 6px;
}
.search-active .search__products {
  grid-row-gap: 10px;
}


@media (max-width: 991px) {
  .search-active .search-overlay {
    display: none;
  }
  .nav-folded .search-dropdown__block {
    margin-top: 79px;
  }
}
@media screen and (max-width: 991px) {
  .search-active .search-dropdown {
    height: 100%;
    max-height: none;
    min-height: 0;
  }
}

.search__icon,
.search-dropdown {
  pointer-events: none;
}
.search-dropdown__block > * {
  pointer-events: auto;
}
.search-dropdown__block {
  overflow-y: auto;
}
.search-box.active-search .search__clear {
  display: flex;
}
.search__input:placeholder-shown {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.search-box.active-search .search__preview {
  display: none;
}
.search-box.active-search .search__results {
  display: block;
}









/* ==== FORM ==== */

.form__control.cb-invalid .form__helper-text {
  display: block;
}
.form__control.cb-invalid .form__input {
  border-color: var(--color-primary);
}
.form__control.cb-success .form__helper-icon {
  display: block;
}

.form__control.cb-disabled {
  pointer-events: none;
}
.form__control.cb-disabled .form__input {
  pointer-events: none;
}
.form__control.cb-disabled .form__helper-arrow {
  opacity: 0.2;
}
.cb-dummy-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  height:auto;
  width:auto;
  min-width: 0px;
  max-width: none;
  border: none;
  padding: 0;
  margin: 0;
}




/* ==== FORM PHONE ===
.form__control.cb-phone input {
  padding-left: 120px;
}
.form__control.cb-phone select {
  max-width: 70px;
  border: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.form__control.cb-phone .form__country-id {
  position: absolute;
  left: 70px;
  top: 50%;
  transform: translatey(-50%);
  padding-left: 15px;
}

.form__control.cb-phone .form__country-id:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  background: #e6e6e6;
  width: 1px;
  height: 32px;
  transform: translatey(-50%);
}




/*-----------------------------------------------------------*/
/* CMS: Blog                                                 */
/*-----------------------------------------------------------*/


.cms-blg__rich-text > *:first-child {
  margin-top: 0px;
}
.cms-blg__rich-text img {
  border-radius: 8px;
}

.article__rich-text ::marker {
  color: var(--color-primary);
}

.article__rich-text .lay__grid {
  padding: 0 120px;
}

.article__rich-text figure div {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.produkte__option__item.cb-active .produkte__option {
  border-color: var(--color-primary);
  border-width: 2px;
}

@media (hover: hover) {
  .produkte__option__item:hover .produkte__option__popover {
    display: block;
  }
  a.cms-blg__info__wrapper:hover {
    text-decoration: underline;
  }
}

@media only screen and (max-width: 767px) {
  .article__main-img,
  .article__rich-text figure {
    width: calc(100% + 40px) !important;
  }
  .article__rich-text figure {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }

  .article__rich-text figure div {
    border-radius: 0 !important;
  }
}
@media only screen and (max-width: 992px) {
  .filter__grid {
    overflow-x: clip;
  }
  .navbar .subnav__list-wrapper,
  .tabs__menu {
    overflow-x: auto;
  }
}


.share-wdw {
  pointer-events: none;
}
.share-modal .share-wdw {
  pointer-events: auto;
}
.share-modal .share-wdw__container {
  opacity: 1;
  transform: scale(1.0);
}
.share-modal .share-wdw__background {
  opacity: 1;
}
.share-wdw__link.disabled {
  display: none;
}

.share-wdw__copy__link.wait {
  display: none;
}
.share-wdw__copy__link.wait.copied {
  display: block;
}


/*-----------------------------------------------------------*/
/* SEVERIN                                                   */
/*-----------------------------------------------------------*/

/* Produkte Items */

@media (hover: hover) {
  .prd-itm__link:hover .prd-utl__title {
    text-decoration: underline;
  }
  .prd-itm__link:hover .prd-itm__buttons {
    opacity: 1;
  }
  .produkt__button:hover {
    width: 34px;
    height: 34px;
    margin: -1px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .07);
  }
  .produkt__button:active {
    width: 32px;
    height: 32px;
    margin: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
  }
}
[cb-status-state=available] .utl-stat {
  background-position: 50% 0px;
}
[cb-status-state=delayed] .utl-stat {
  background-position: 50% -12px;
}
[cb-status-state=unclear] .utl-stat {
  background-position: 50% -24px;
}
[cb-status-state=unavailable] .utl-stat {
  background-position: 50% -36px;
}

[cb-status-state=available].utl-stat__wrapper {
  color: #589b2a;
}
[cb-status-state=delayed].utl-stat__wrapper {
  color: #c66503;
}
[cb-status-state=unclear].utl-stat__wrapper {
  color: #8D8D8D;
}
[cb-status-state=unavailable].utl-stat__wrapper {
  color: #C50035;
}


/* Blog Items */

@media (hover: hover) {
  .blg-itm:hover .blg-itm__img {
    transform: scale(1.05);
  }
  .blg-itm-featured__img-wrapper:hover .blg-itm__img,
  .blg-featured__img:hover .blg-itm__img {
    transform: scale(1.02);
  }
}
.blg-itm-featured__img-wrapper .blg-itm__img {
  transition-duration: 0.6s;
}

/* Sidenav */


.sidenav.sticky {
  position: sticky;
}

@media (hover: hover) {
  .sidenav__item:not(.open) a.sidenav__main-link:hover {
    color: var(--color-primary);
  }
  .sidenav__item.open > .sidenav__main-link .sidenav__item__close:hover .utl__hover-circle,
  .utl-modal__close:hover .utl__hover-circle {
    opacity: 1;
    transform: scale(1);
  }
}
.sidenav {
  top: var(--top-offset);
}
.sidenav__item.open {
  padding-left: 16px;
}
.sidenav__item.open > .sidenav__main-link {
  font-weight: 500;
  border-bottom: none;
}

.sidenav__item.open > .sidenav__main-link:not(:last-child) {
  padding-bottom: 0;
  margin-left: -16px;
  min-width: calc(100% + 16px);
  border-bottom: none;
}

@media (max-width: 991px) {
  .nav-open .navbar .sidenav__item.open > .sidenav__main-link:not(:last-child) {
    padding-bottom: 0;
  }
}

.sidenav__item.open:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.sidenav__item:last-child .sidenav__main-link {
  border: none;
}

.sidenav__list.open-child > .sidenav__item {
  color: #cecece;
}
.sidenav__item.open:not(.open-child) {
  color: var(--black-text);
}

.sidenav__list.open-child > .sidenav__item:not(.open) .sidenav__main-link {
  border-color: #f0f0f0;
}
.sidenav__item__close,
.sidenav__item.open:not(.open-child) > .sidenav__main-link .sidenav__arrow {
  display: none;
}
.sidenav__item.open > .sidenav__main-link {
  cursor: text;
}
.sidenav__item.open:not(.open-child) > .sidenav__main-link .sidenav__item__close {
  display: flex;
  cursor: pointer;
}


/* Shop the Look */

.stl__item .utl-points__wrapper {
  width: 100%;
}


/* Cookie Banner */

.fs-cc-main {
  z-index: 10000;
}


/* Card Icon */

.card-icon::before {
  content: "";
  display: inline-block;
  position: relative;
  width: 12px;
  height: 8px;
  background-image: url(https://uploads-ssl.webflow.com/64355847bcb5609ea6eedbcd/64e8bca4e62fe95f350287b1_mk-card-icon.svg);
  background-position: 50% 0;
  background-repeat: no-repeat;
  margin-right: 6px;
  bottom:1px;
}



/* Categories */

.cat-circular .cat-itm__img-container {
  border-radius: 50%;
}

/* Home USP */

.h-usp__item:last-child {
  border: none;
}


/* Rich Text */

.utl__rich-text > *:first-child {
  margin-top: 0;
  padding-top: 0;
}
.utl__rich-text > *:last-child:empty {
  display: none;
}
.utl__rich-text.left > * {
  margin-left: 0;
}


/*-----------------------------------------------------------*/
/* Navigation                                                */
/*-----------------------------------------------------------*/

/* Language Picker */

.nav-lg.active .nav-lg__list {
  display: block;
}
.nav-lg.active .nav-lg__block {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.nav-lg.dd-up .nav-lg__block {
  justify-content: flex-end;
}
.nav-lg.dd-up .nav-lg__list {
  margin-top: 0;
  margin-bottom: -7px;
  padding-top: 7px;
  padding-bottom: 0;
  order: -1;
}

/* Intercom */

body .intercom-lightweight-app {
  z-index: 900;
}
@media (max-width: 1580px) {
  .intercom-lightweight-app .intercom-lightweight-app-launcher {
    transform: translateY(0px);
    opacity: 1;
    transition: all .2s cubic-bezier(.165, .84, .44, 1);
  }
  .intercom-lightweight-app.intercom-hide .intercom-lightweight-app-launcher {
    transform: translateY(0px);
    opacity: 0;
    pointer-events: none;
  }
}


/* Other */

[nav-profile-state=login] .nav-profile {
  display: none;
}
[nav-profile-state=login] .nav-login {
  display: flex;
}
.nav-profile {
  z-index: 100;
}

@media (hover:hover) {
  .subnav__link:hover,
  .nav-lg__link:hover {
    color: var(--color-primary);
  }
}

.subnav__link.w--current::after {
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  bottom: -1px;
  right: 15px;
  background-color: var(--color-primary);
  width: auto;
  height: 1px;
}

.nav-folded .navbar__bottom {
  transform: translate(0, -55px);
}
.prd__sticky-wrapper {
  transform: translate(0, calc(-100%));
}
.quickview-active .prd__sticky-wrapper {
  transform: translate(0, calc(0% + 126px));
  opacity: 1;
}
.quickview-active.nav-folded .prd__sticky-wrapper {
  transform: translate(0, calc(0% + 72px));
}

@media screen and (max-width: 991px) {
  .quickview-active .prd__sticky-wrapper {
    transform: translate(0, -100%);  /* calc(0% + 121px) to show */
  }
}


.nav-fixed .navbar__bottom {
  position: fixed;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.locked body {
  width:100%;
}

.prd-dd__info .utl__select select,
.prd-dd__info .utl__select input {
  width: auto;
  height: auto;
  padding: 0 14px 0 8px;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
}

.prd-dd__info .utl__select.amount,
.prd-dd__info .utl__select.type,
.prd-dd__info .utl__select input,
.prd-dd__info .utl__select select {
  min-width: calc(var(--content-width) + 22px);
  width: calc(var(--content-width) + 22px);
}
.prd-dd__info .utl__select .utl__select__icon {
  right: 0;
}
.navbar__top__gradient {
  pointer-events: none;
}

@media (max-width: 991px) {
  .nav-fixed .navbar__top {
    position: fixed;
    top: 0px;
    animation-name: navbarFixedMobile;
    animation-duration: 0.5s;
    transition: top 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  .scrolled-start.nav-fixed .navbar__top {
    animation-name: navbarFixedMobileScrolled;
  }
  .nav-folded.nav-fixed .navbar__top {
    top: -51px;
  }
}

@keyframes navbarFixedMobile {
  0% {
    top: -130px;
  }
  100% {
    top: -51px;
  }
}
@keyframes navbarFixedMobileScrolled {
  0% {
    top: -130px;
  }
  100% {
    top: 0px;
  }
}


.utl__hover-circle {
  pointer-events: none;
}

@media (hover: hover) {
  .nav-icon:hover .utl__hover-circle,
  .notification__close:hover .utl__hover-circle,
  .update-box__close:hover .utl__hover-circle,
  .nav-ml-dd__options:hover .utl__hover-circle,
  .utl-ipt__info__icon__wrapper:hover .utl__hover-circle,
  .navbar__notification__close:hover .utl__hover-circle {
    opacity: 1;
    transform: scale(1);
  }
}
.nav-svg__mobile {
  display: none;
}
.nav-svg__desk {
  display: block;
}
@media screen and (max-width: 767px) {
  .nav-svg__mobile {
    display: block;
  }
  .nav-svg__desk {
    display: none;
  }
}

/* Navigation Dropdowns */

.nav-icon__counter:empty {
  display: none;
}
.nav-dd__list {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-dd__list::-webkit-scrollbar {
  display: none;
}

.nav-dd__block {
  max-height: calc(100vh - 120px);
}
.nav-ml-dd__dropdown.active .nav-ml-dd__arrow {
  transform: rotate(90deg);
}
.nav-dd.active .nav-dd__block {
  pointer-events: auto;
  opacity: 1;
  transform: translate(0, 0px);
}
.nav-dd,
.nav-dd__block,
.nav-dd:not(.active) *,
.nav-dd__arrow {
  pointer-events: none;
}
.nav-crt__list .prd-dd__item:last-child {
  border-bottom: none;
}
@media (hover: hover) {
  .nav-ml-dd__toggle:hover {
    background-color: var(--grey-bg);
  }
}


/* Navigation Dropdown Options */

.nav-ml-dd__options__window {
  pointer-events: none;
}
.nav-ml-dd__options__window.active {
  pointer-events: auto;
  transform: translate(0);
  opacity: 1;
}


/* Mobile Menu */

.navbar__menu__open,
.nav-open .navbar__menu__closed {
  display: none;
}
.nav-open .navbar__menu__open {
  display: block;
}
.navbar .sidenav {
  display: none;
  top: 0;
}

@media (min-width: 992px) {
  .subnav__link {
    height: calc(100% + 1px);
  }
}
@media (max-width: 991px) {
  .navbar .sidenav {
    display: flex;
  }
  .subnav__item.subnav__item__secondary {
    display: none;
  }
  .lay__container.navbar__sublist-container {
    height: calc(100% - 122px);
  }
  .nav-folded .lay__container.navbar__sublist-container {
    margin-top: 70px;
    height: calc(100% - 70px);
  }
  .nav-folded .navbar__bottom {
    transform: translate(0px);
  }
  .nav-open .navbar__bottom {
    height: 100%;
  }
  .nav-open .navbar__top__gradient {
    opacity: 0;
  }
  .nav-open .subnav__overflow-container {
    overflow-y: auto;
  }
  .subnav__link.w--current::after {
    bottom: 0;
  }
  .navbar .sidenav__main-link {
    padding: 6px 0;
  }
  .nav-open .navbar .sidenav__main-link {
    padding: 16px 0;
  }
}



/*-----------------------------------------------------------*/
/* Navigation: Notification                                  */
/*-----------------------------------------------------------*/


.nav-notification .navbar__notification {
  display: flex;
}
.navbar__notification__text a {
  text-decoration: none;
  opacity: 0.6;
}
.navbar__notification__paragraph {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.navbar__notification__close .utl__hover-circle {
  background-color: rgb(255 255 255 / 10%);
}
@media (hover: hover) {
  .navbar__notification__text a:hover {
    text-decoration: underline;
    opacity: 1;
  }
}
.nav-notification .navbar {
  height: 167px;
}
@media screen and (max-width: 991px) {
  .nav-notification .navbar {
    height: 163px;
  }
}
.nav-notification .navbar__top {
  top: 40px;
}
.nav-notification .navbar__bottom {
  top: 113px;
}
.quickview-active.nav-folded .nav-notification .prd__sticky-wrapper,
.quickview-active.nav-folded.nav-notification .prd__sticky-wrapper {
  transform: translate(0, calc(0% + 112px));
}
.nav-notification .prd__sticky-wrapper {
  transform: translate(0, calc(-100%));
}

.navbar__notification strong {
  color: white;
}

@media (max-width: 991px) {
  .nav-fixed .nav-notification .navbar__top,
  .nav-fixed.nav-notification .navbar__top {
    top: 0;
  }
  .nav-folded.nav-fixed .nav-notification .navbar__top,
  .nav-folded.nav-fixed.nav-notification .navbar__top {
    top: -51px;
  }
  .quickview-active.nav-folded .nav-notification .prd__sticky-wrapper,
  .quickview-active.nav-folded.nav-notification .prd__sticky-wrapper {
    transform: translate(0, calc(0% + 72px));
  }
  .nav-notification .navbar__bottom {
    top: 0;
  }
  html:not(.nav-fixed) .nav-notification .lay__container.navbar__sublist-container,
  html.nav-notification:not(.nav-fixed) .lay__container.navbar__sublist-container {
    height: calc(100% - 162px);
    margin-top: 162px;
  }
  html:not(.nav-fixed) .nav-notification .search-dropdown__block,
  html.nav-notification:not(.nav-fixed) .search-dropdown__block {
    margin-top: 170px;
  }
}




/*-----------------------------------------------------------*/
/* Forms                                                     */
/*-----------------------------------------------------------*/


.utl-ipt__info__icon__wrapper,
.utl-ipt__info {
  pointer-events: none;
}
.utl-ipt__info__text,
.utl-ipt__info .utl__button {
  pointer-events: auto;
}
select.utl-ipt__field {
  cursor: pointer;
}
.utl-ipt__field {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.w-input.utl-ipt__field[disabled],
.w-select.utl-ipt__field[disabled] {
  color: #D2D2D2;
  background-color: transparent;
}
.w-input.utl-ipt__field[disabled] ~ .utl-ipt__info,
.w-select.utl-ipt__field[disabled] ~ .utl-ipt__info {
  opacity: 0.3;
}
.utl-ipt__field {
  background-color: transparent;
}





/* correct */

[cb-input-status=correct] .utl-ipt__info__icon {
  background-position: 50% -20px;
}


/* false */

[cb-input-status=false] .utl-ipt__info__icon {
  background-position: 50% 0px;
}
[cb-input-status=false] .utl-ipt__field,
[cb-input-status=text-false] .utl-ipt__field {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
[cb-input-status=false] .utl-ipt__field:focus,
[cb-input-status=text-false] .utl-ipt__field:focus,
[cb-input-status=false] .utl-ipt__field__wrapper.country-select .utl-ipt__field:focus {
  border-color: var(--color-primary-hover);
}
[cb-input-status=false] .utl-ipt__field__wrapper.country-select .utl-ipt__field:focus ~ .utl-ipt__input__divider {
  background-color: var(--color-primary-hover);
}
[cb-input-status=false] .utl-ipt__input__divider,
[cb-input-status=text-false] .utl-ipt__input__divider {
  background-color: var(--color-primary);
}
[cb-input-status=false] .utl-ipt__info__text:not(:empty, .wf-empty) {
  display: block;
}


/* encrypted/decrypted */

[cb-input-password=encrypted] .utl-ipt__info__icon__wrapper,
[cb-input-password=decrypted] .utl-ipt__info__icon__wrapper {
  pointer-events: auto;
}
[cb-input-password=encrypted] .utl-ipt__info__icon {
  background-position: 50% -40px;
}
[cb-input-password=decrypted] .utl-ipt__info__icon {
  background-position: 50% -60px;
}
[cb-input-password=encrypted] input[type='password'] {
  font-family: Verdana;
  letter-spacing: 0.125em;
}

/* select */

[cb-input-type=select] .utl-ipt__info__icon__wrapper.select {
  display: flex;
}
[cb-input-type=select] .utl-ipt__info__icon__wrapper.select .utl-ipt__info__icon {
  background-position: 50% -80px;
}


/* phone */

[cb-input-type=phone] .country-select .utl-ipt__info__icon__wrapper.select {
  display: flex;
}
[cb-input-type=phone] .country-select .utl-ipt__info__icon__wrapper.select .utl-ipt__info__icon {
  background-position: 50% -80px;
}
.utl-ipt__field__wrapper.country-select .utl-ipt__field {
  border-radius: 0;
  border: none;
  padding-right: 20px;
}
[cb-input-type=phone] .utl-ipt__field__wrapper:not(.country-select) .utl-ipt__field {
  padding-left: 92px;
}
.utl-ipt__field__wrapper.country-select .utl-ipt__field:focus {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #bebebe;
  position: relative;
  z-index: 10;
  padding: 15px 19px 15px 19px;
  border-right: none;
}

/* Coupon */

[cb-input-type=coupon] .utl-ipt__info .utl__button {
  padding: 10px 14px;
  min-height: 34px;
}
[cb-input-type=coupon] .utl-ipt__field {
  padding-left: 16px;
  padding-right: 120px;
}


.pass-strength__item .utl__icon-embed {
  color: #e6e6e6;
}
.pass-strength__item.pass .utl__icon-embed {
  color: #73c44d;
}





/*

cb-input-status=correct
cb-input-status=text
cb-input-status=text-false
cb-input-status=false
cb-input-status=encrypted
cb-input-status=decrypted

cb-input-type=password
cb-input-type=select

*/







/*-----------------------------------------------------------*/
/* Get The Look Tooltips                                     */
/*-----------------------------------------------------------*/

.utl-points__dot {
  background: transparent;
  backdrop-filter: blur(50px);
  --webkit-backdrop-filter: blur(50px);
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.utl-points__dot::after {
  position: absolute;
  display: block;
  content: "";
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
.utl-points__tooltip-wrapper {
  pointer-events: none;
}
.utl-points__tooltip {
  visibility: hidden;
  opacity: 0;
}
@media (hover: hover) {
  .utl-points__point:hover .utl-points__tooltip {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible;
    pointer-events: auto;
  }
  .utl-points__point:hover .utl-points__dot {
    border: 2px solid white;
  }
  .utl-points__point:hover {
    z-index: 200;
  }
  .utl-points__tooltip .prd-itm__info:hover .prd-utl__title {
    text-decoration: underline;
  }
}

.utl-points__tooltip .prd-itm__info {
  padding: 0px;
}



[cb-tooltip-window=top-left] .utl-points__tooltip-wrapper {
  top: auto;
  right: 0;
  bottom: 100%;
  left: auto;
}
[cb-tooltip-window=top-center] .utl-points__tooltip-wrapper {
  top: auto;
  right: auto;
  bottom: 100%;
  left: auto;
}
[cb-tooltip-window=top-right] .utl-points__tooltip-wrapper {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 0;
}
[cb-tooltip-window=right-top] .utl-points__tooltip-wrapper {
  top: auto;
  right: auto;
  bottom: 0;
  left: 100%;
}
[cb-tooltip-window=right-center] .utl-points__tooltip-wrapper {
  top: auto;
  right: auto;
  bottom: auto;
  left: 100%;
}
[cb-tooltip-window=right-bottom] .utl-points__tooltip-wrapper {
  top: 0;
  right: auto;
  bottom: auto;
  left: 100%;
}
[cb-tooltip-window=bottom-left] .utl-points__tooltip-wrapper {
  top: 100%;
  right: 0;
  bottom: auto;
  left: auto;
}
[cb-tooltip-window=bottom-center] .utl-points__tooltip-wrapper {
  top: 100%;
  right: auto;
  bottom: auto;
  left: auto;
}
[cb-tooltip-window=bottom-right] .utl-points__tooltip-wrapper {
  top: 100%;
  right: auto;
  bottom: auto;
  left: 0;
}
[cb-tooltip-window=left-top] .utl-points__tooltip-wrapper {
  top: auto;
  right: 100%;
  bottom: 0;
  left: auto;
}
[cb-tooltip-window=left-center] .utl-points__tooltip-wrapper {
  top: auto;
  right: 100%;
  bottom: auto;
  left: auto;
}
[cb-tooltip-window=left-bottom] .utl-points__tooltip-wrapper {
  top: 0;
  right: 100%;
  bottom: auto;
  left: auto;
}






/*-----------------------------------------------------------*/
/* CMS Product                                               */
/*-----------------------------------------------------------*/

.nav-fixed .prd__sticky {
  position: fixed;
}
.prd__sticky__block select {
  height: 42px;
  background-position: calc(100% - 20px) calc(1rem + 2px), calc(100% - 15px) calc(1rem + 2px);
}
.produkte__option__popover {
  pointer-events: none;
}
.prd__sticky__block .prd-lst__img-container {
  width: 60px;
  height: 60px;
}
@media (hover: hover) {
  .utl-acc__toggle:hover {
    text-decoration: underline;
  }
}



.prd__sticky__block {
  container-type: inline-size;
}
@container (max-width: 950px) {
  .prd__sticky__block .utl__select.amount {
    width: 30px;
    min-width: 0px;
  }
  .prd__sticky__block .utl__select.type {
    min-width: 120px;
  }
  .prd__sticky__block .utl__select select,
  .prd__sticky__block .utl__select input {
    padding-right: 25px;
  }
  .prd__sticky__block .utl__select[cb-amount-element="amount"] input {
    padding-right: 16px;
  }
}
@container (max-width: 780px) {
  .prd__sticky__block .utl__button-text {
    display: none;
  }
  .prd__sticky__block .utl__button:not(.small) .utl__icon-embed:first-child {
    margin: 0;
  }
  .prd__sticky__block .utl__button {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@container (max-width: 600px) {
  .prd__sticky__block .utl-amount {
    display: none;
  }
}
@container (max-width: 350px) {
  .prd__sticky__block [cb-quicklink-element=list] {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .prd__sticky__block .utl-amount {
    order: inherit;
  }
}
@media screen and (max-width: 767px) {
  .prd__sticky__block .utl-amount {
    width: auto;
  }
}
@media screen and (max-width: 300px) {
  .prd__sticky-wrapper {
    display: none;
  }
}



.prd-pg__dl__list-item .utl__button.small {
  padding: 8px 10px;
  background-color: #f2f2f2;
  border-radius: 4px;
  font-weight: 400;
  min-width: 300px;
  justify-content: left;

}
@media (hover: hover) {
  .prd-pg__dl__link:hover {
    background-color: #f2f2f2;
  }
  .prd-pg__dl__cta:hover {
    text-decoration: underline;
  }
}
.prd-pg__dl__list-item .locked,
.prd-pg__dl__list-item [cb-locked-state=locked] .prd-pg__dl__cta {
  display: none;
}
.prd-pg__dl__list-item [cb-locked-state=locked] .locked {
  display: block;
}
[cb-locked-state=locked] {
  pointer-events: none;
  opacity: 0.4;
}



/*-----------------------------------------------------------*/
/* Order List                                                */
/*-----------------------------------------------------------*/

.ord-lst {
  container-type: inline-size;
}

@container (min-width: 1100px) {
  .ord-lst__info-column.total {
    width: 150px;
  }
  .ord-lst__info-column.payment {
    width: 130px;
  }
  .ord-lst__info-column.shipping {
    width: 200px;
  }
}
@container (max-width: 850px) {
  .ord-lst .ord-lst__caption {
    display: none;
  }
  .ord-lst__info-column.total,
  .ord-lst__info-column.payment,
  .ord-lst__info-column.shipping {
    width: auto;
  }
  .ord-lst__info {
    width: 100%;
  }
  .ord-lst__info {
    grid-column-gap: 16px;
    grid-row-gap: 2px;
  }
}
@container (max-width: 500px) and (min-width: 331px) {
  .ord-lst__button__text {
    display: none
  }
  .utl__icon-embed.ord-lst__button__icon {
    display: flex;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 50%;
    color: var(--black-text);
  }
}
@container (max-width: 530px) {
  .ord-lst__info {
    grid-column-gap: 8px;
  }
}
@container (max-width: 330px) {
  .ord-lst__wrapper {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }
}



/*-----------------------------------------------------------*/
/* Product List                                              */
/*-----------------------------------------------------------*/

.prd-lst__item {
  container-type: inline-size;
}
.prd-dd__wrapper .prd-lst__item {
  padding: 16px 0;
  border-bottom: 1px solid var(--border);
}
.prd-dd__wrapper .prd-lst__item:last-child {
  border: none;
}
.utl__button.prd-lst__buttons {
  transition: all .2s cubic-bezier(.175, .885, .32, 1.275);
}
.utl__button.prd-lst__buttons .prd-lst__button__text {
  display: none;
}
.utl__button.prd-lst__buttons .utl__icon-embed:first-child {
  margin: 0px;
}

.prd-lst__item[cb-prodlist-type=static] .utl-amount,
.prd-lst__item[cb-prodlist-type=static] .prd-lst__button-wrapper,
.prd-lst__item[cb-prodlist-type=cart] [cb-quicklink-element=cart],
.prd-lst__item[cb-prodlist-type=list] [cb-quicklink-element=list] {
  display: none;
}
.prd-lst__item[cb-prodlist-type=static] .prd-lst__quantity__static {
  display: block;
}
.prd-lst__item[cb-prodlist-type=static] .prd-lst__title-wrapper {
  max-width: 240px;
}
.prd-lst__item[cb-prodlist-type=static] .prd-lst__quantity-wrapper {
  margin-right: auto;
}
.prd-lst__item[cb-prodlist-type=static] .prd-lst__info-wrapper {
  width: 350px;
}
.prd-lst__item[cb-prodlist-type=static] .prd-lst__img-container {
  width: 60px;
  height: 60px;
}
.prd-lst__item[cb-prodlist-type=static] .prd-lst__content {
  overflow: hidden;
}
.prd-lst__item .utl-stat {
  display: none;
  top: 8px;
  right: 8px;
  width: 10px;
  height: 10px;
  background-size: 10px;
}


@media (hover: hover) {
  .prd-lst__item .prd-lst__title-wrapper a:hover .prd-utl__title,
  .notf-bag__item__info:hover .prd-utl__title {
    text-decoration: underline;
  }
  .utl__button.prd-lst__buttons:hover {
    width: 34px;
    height: 34px;
    margin: -1px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .07);
  }
  .utl__button.prd-lst__buttons:active {
    width: 32px;
    height: 32px;
    margin: 0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
  }
  a.prd-lst__img-container:hover,
  .cat-itm:hover,
  .prd-itm__img-wrapper:hover {
    background-color: #f7f7f7;
  }
}




@container (max-width: 1000px) {
  .prd-lst__quantity__static {
    margin-right: 0px;
  }
}

@container (max-width: 780px) {
  .prd-lst__item .utl__select.amount {
    width: 30px;
    min-width: 0px;
  }
  .prd-lst__item[cb-prodlist-type=static] .prd-lst__info-wrapper {
    width: 250px;
  }
  .prd-lst__item .utl__select.type {
    min-width: 120px;
  }
  .prd-lst__item .utl__select select,
  .prd-lst__item .utl__select input {
    padding-right: 25px;
  }
  .prd-lst__item .utl__select[cb-amount-element="amount"] input {
    padding-right: 16px;
  }
}

@container (max-width: 550px) {
  .prd-lst__item[cb-prodlist-type=static] .prd-lst__quantity-wrapper {
    display: none;
  }
  .prd-lst__item[cb-prodlist-type=static] .prd-lst__info-wrapper {
    width: auto;
  }
  .prd-lst__item[cb-prodlist-type=static] .prd-lst__price-wrapper {
    min-width: 80px;
  }
}

@container (max-width: 620px) {
  .utl__button.prd-lst__buttons {
    border: none;
    width: auto;
    color: var(--text-light);
    overflow: visible;
    height: auto;
    border-radius: 0px;
  }
  .lay__box.rows-10 .prd-lst__item:not(:last-child) .prd-lst__main-wrapper {
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border);
  }
  .prd-lst__title-wrapper {
    min-width: auto;
  }
  .utl__button.prd-lst__buttons:hover {
    color: var(--black-text);
  }
  .utl__button.prd-lst__buttons .prd-lst__button__text {
    display: inline-block;
  }
  .utl__button.prd-lst__buttons .utl__icon-embed:first-child {
    margin-left: -5px;
    bottom: 1px;
  }
  .prd-lst__button-wrapper {
    order: 1;
    width: 100%;
  }
  .prd-lst__button-wrapper {
    grid-column-gap: 16px;
  }
  .prd-lst__main-wrapper {
    grid-column-gap: 10px;
  }
  .prd-lst__item:not([cb-prodlist-type=static]) .prd-lst__info-wrapper {
    width: 100%;
  }
  .prd-lst__item:not([cb-prodlist-type=static]) .prd-lst__content {
    flex-direction: column;
  }
  .prd-lst__item .utl__select select,
  .prd-lst__item .utl__select input {
    width: auto;
    height: auto;
    padding: 0 14px 0 2px;
    border: none;
    border-radius: 0;
    -webkit-appearance: none;
  }


  .prd-lst__item .utl__select.amount,
  .prd-lst__item .utl__select.type,
  .prd-lst__item .utl__select input,
  .prd-lst__item .utl__select select {
    min-width: calc(var(--content-width) + 22px);
    width: calc(var(--content-width) + 22px);
  }
  .prd-lst__item .utl__select .utl__select__icon {
    right: 0;
  }
  [cb-prodlist-type]:not([cb-prodlist-type=static]) .prd-lst__price-wrapper {
    margin-left: auto;
    flex-direction: row;
  }
  [cb-prodlist-type]:not([cb-prodlist-type=static]) .prd-lst__price {
    order: 1;
  }

  .prd-lst__img-container {
    width: 60px;
    height: 60px;
  }
  .utl__button.prd-lst__buttons:hover {
    width: auto;
    height: auto;
    margin: 0;
    box-shadow: none;
  }
  .sp-bag__box .prd-lst__item .prd-lst__main-wrapper {
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--border);
  }
  .sp-bag__box .prd-lst__item:last-child .prd-lst__main-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

@container (max-width: 430px) {
  .prd-lst__price-wrapper {
    display: block;
  }
  .prd-lst__item:not([cb-prodlist-type=static]) .prd-lst__quantity {
    display: none;
  }
  .prd-lst__price {
    order: 0;
  }
}



/*-----------------------------------------------------------*/
/* Compatible Products Page                                  */
/*-----------------------------------------------------------*/

@media (max-width: 550px) {
  .kp-box__wrapper .utl__optional {
    display: none;
  }
}
@media (max-width: 460px) {
  .kp-box__wrapper .utl__optional {
    display: inline;
  }
  .kp-box__wrapper .utl__button {
    width: 100%;
  }
}


/*-----------------------------------------------------------*/
/* About Page                                                */
/*-----------------------------------------------------------*/


.u-team__wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.u-team__wrapper.start-animation {
  animation-name: testimonialmove;
  animation-duration: calc(var(--amount) * 0.8s);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


@keyframes testimonialmove {
  0% {
    -webkit-transform: translate(-33.33333%, 0px);
    -ms-transform: translate(-33.33333%, 0px);
    transform: translate(-33.33333%, 0px);
  }
  100% {
    -webkit-transform: translate(-66.6666%, 0px);
    -ms-transform: translate(-66.6666%, 0px);
    transform: translate(-66.6666%, 0px);
  }
}



@media (hover: hover) {
  .standorte__card__map__btn:hover {
    width: 44px;
    height: 44px;
    margin: -2px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, .07);
  }
  .u-kunden__link:hover {
    filter: none;
    opacity: 1;
  }
}




/*-----------------------------------------------------------*/
/* Privacy Bee                                               */
/*-----------------------------------------------------------*/

body .KFaIpG_wrapper {
  max-width: 100%;
}
body .KFaIpG_h1,
body .KFaIpG_h2,
body .KFaIpG_h3,
body .CCca4W_expandableName,
body .KFaIpG_link {
  font-weight: 500;
  color: var(--black-text);
}
body .KFaIpG_h1.KFaIpG_pt14 {
  display: none;
}
body .KFaIpG_text {
  line-height: 1.8;
  max-width: 800px;
  color: var(--black-text);
}
body .oSc-KW_listWrapper {
  max-width: 800px;
}
body .CCca4W_expandableWrapper {
  border-color: var(--border);
  border-radius: 16px;
}
body .KFaIpG_listElement {
  color: var(--black-text);
}




/*-----------------------------------------------------------*/
/* Warenkorb                                                 */
/*-----------------------------------------------------------*/


.sp-bag__add .utl__icon-embed {
  pointer-events: none;
}















