.utl-ipt__info__text ul{
    list-style-type: none;
    padding-left: 0;
}

.utl-ipt__field_prefix {
    padding-right: 32px !important;
    overflow: hidden !important;
    text-overflow: clip !important;
}

.utl-ipt__info__text {
    color: #c50035;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    flex: none;
    margin-top: 8px;
    padding: 0;
    /*display: none;*/
    position: relative;
}

.pin-add-wishlist.button {
    padding: 0 !important;
    background: transparent !important;
}

.modal-inactive {
    display: none;
}

.modal-active {
    display: block;
}

.pay_button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    text-align: center;
    font-size: 11px;
    background: #c50035;
    padding: 6px 1px;
    color: #fff; text-decoration: none;
    border-radius: 50px;
}
/*-----------------------------------------------------------*/
/* Account                                                   */
/*-----------------------------------------------------------*/

a {
    color: inherit;
  }

.label_change_avatar {
    cursor: pointer;
    font-weight: normal !important;
}


  /* Order Box */

[cb-acc-element=edit] {
    display: none;
}

[cb-acc-element=edit-address] {
    display: none;
}

.lay__box.active-edit [cb-acc-element=edit-address] {
    opacity: 1;
    pointer-events: auto;
    display: flex;
    border-top: none;
}

.lay__box.active-edit [cb-acc-element=overview] {
    display: none;
}

/* -- START -- on Checkout page display and hide manage address */
[cb-acc-element=manage-address] {
    display: none;
}

.lay__box.active-manage [cb-acc-element=manage-address] {
    opacity: 1;
    pointer-events: auto;
    display: flex;
    border-top: none;
}

.lay__box.active-manage [cb-acc-element=overview] {
    display: none;
}
/* -- END -- on Checkout page display and hide manage address */

.cb-swiper-item.carousel_item_fix {
    display: inline !important;
}

/*-----------------------------------------------------------*/
/* CART PAGE                                            */
/*-----------------------------------------------------------*/

  .nav-lg {
    cursor: pointer;
  }

/*-----------------------------------------------------------*/
/* SEARCH HEADER                                             */
/*-----------------------------------------------------------*/

.no-search-entries, .no-preview-entries, .no-preview-last-entries, .no-autocomplete-entries {
    opacity: 0.5;
    font-style: italic;
}

/*overwrite from semantic.css*/
.ui.button {
    font-weight: normal;
    color: #222;
}

/*overwrite from semantic.css*/
a:hover {
    color: #000000;
}

/*overwrite from semantic.css*/
a.utl__button:hover {
    color: white;
    text-decoration: none;
}

a.pay_button:hover{
    color: white;
    text-decoration: none;
}

.u-contact__card a.utl__button:hover {
    color: #000000;
} 

.footer__help__action a.utl__button:hover{
    color: #000000;
}

.flt-ipt__list__button-wrapper a.utl__button.neg-outline:hover{
    color: #000000;
}

.lay__button-wrapper a.utl__button.neg-outline:hover{
    color: #000000;
}

.lay__button-wrapper.centered a.utl__button:hover{
    color: #000000;
}

.lay__box__wrapper a.utl__button.neg-outline:hover{
    color: #000000;
}

.fs-cc-prefs_space-medium a.utl__button:hover{
    color: #000000;
}

h1, h2, h3, h4, h5 {
    font-family: Graphik, sans-serif;
}

.utl__icon-embed.sidenav__arrow {
    margin-left: auto;
}

a.lay__carousel__nav-button[aria-disabled="false"]:hover{
    color: white;
}

.fs-cc-prefs_space-medium a[fs-cc="allow"]:hover {
    color: white;
}

.fs-cc-banner_buttons-wrapper a[fs-cc="deny"]:hover {
    color: #000000;
}

.prd-lst__button-wrapper a[cb-quicklink-element="remove"]:hover {
    color: #000000;
}

a.utl__button.neg-outline.radius-4:hover {
    color: #000000;
}

/*-----------------------------------------------------------*/
/* BUTTONS                                                   */
/*-----------------------------------------------------------*/

.utl__button:focus-visible {
    outline: unset;
}

.utl__button:hover {
    cursor: pointer;
}

.utl-text-button:hover {
    cursor: pointer;
}


/*-----------------------------------------------------------*/
/* LOGIN BUTTON                                              */
/*-----------------------------------------------------------*/

.utl__button.nav-login {
    display: flex !important;
}

/*-----------------------------------------------------------*/
/* CHECKOUT BUTTON                                              */
/*-----------------------------------------------------------*/

.utl__button_checkout_next {
    min-height: 36px !important;
    grid-column-gap: 4px !important;
    color: #fff !important;
    white-space: nowrap !important;
    background-color: #c50035 !important;
    border: 1px solid #c50035 !important;
    border-radius: 100px;
    flex: none;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 7px 25px !important;
    font-size: 14px !important;
    line-height: 1.2 !important;
    text-decoration: none !important;
    display: flex;
}

.utl__button_checkout_next:hover {
    background-color: #d50039 !important;
    border-color: #d50039 !important;
    left: -4px !important;
    right: -4px !important;
}

.utl__button_checkout_next:focus {
    background-color: #d50039 !important;
    border-color: #d50039 !important;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
}

.utl__button_checkout_back {
    min-height: 36px !important;
    grid-column-gap: 4px !important;
    color: #000 !important;
    white-space: nowrap !important;
    background-color: #f4f4f4 !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 100px;
    flex: none;
    justify-content: center !important;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 7px 25px !important;
    font-size: 14px !important;
    line-height: 1.2 !important;
    text-decoration: none !important;
    display: flex;
}

.utl__button_checkout_back:hover {
    background-color: #ebebeb !important;
    border-color: #cecece !important;
}

.red__input_checked_checkout_label .ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before
{
    background-color: #c50035 !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before, .ui.toggle.checkbox input:focus:checked ~ label:before
{
    background-color: #c50035 !important;
}

.align_right {
    margin-left: auto;
}

.hidden_element{
    display: none;
}

.without_border_bottom{
    border-bottom: 0;
}

.button-submit-checkout {
    /*background-color: rgba(40, 40, 40, 0.3) !important;*/
    color: #000;
    background-color: #f4f4f4;
    border-color: #d9d9d9;
    cursor: not-allowed !important;
}

.button-submit-checkout:hover {
    border-color: #d9d9d9;
    background-color: #f4f4f4;
}

.left-20 {
    padding-left: 20px;
}

.text_error_color {
    color: #c50035
}

.no_underline_link a{
    text-decoration: none !important;
}

.sidenav__link:hover {
    color: var(--color-primary);
}

.sidenav__link_mobile {
    padding-right: 60%;
}

.prd-itm__link-association-prod {
    text-decoration: none;
    width: 100% !important;
}

.prd-utl__artnr {
    border: 1px solid var(--border);
    cursor: text;
    border-radius: 4px;
    margin-top: 6px;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
}


/*-----------------------------------------------------------*/
/* Product Page                                              */
/*-----------------------------------------------------------*/

@media screen and (min-width: 767px) {
    .variant_options_width {
        max-width: 195px;
    }
}

/*-----------------------------------------------------------*/
/* SPINNER                                                   */
/*-----------------------------------------------------------*/

#spinnerOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.8);
}

#spinnerOverlay .spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#spinnerOverlay .spinnerContainer .spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #d80f0f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*-----------------------------------------------------------*/


/*-----------------------------------------------------------*/
/* SPINNER                                                   */
/*-----------------------------------------------------------*/

#spinnerOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.8);
}

#spinnerOverlay .spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#spinnerOverlay .spinnerContainer .spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #d80f0f;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*-----------------------------------------------------------*/
.produkte__option__item .produkte__option:hover {
    border-color: var(--color-primary);
    border-width: 1px;
}

/*------------ Prod Page description limit text -------------------*/

.text-limit-3 {
    max-height: 3em; /* Set the maximum height for 3 lines of text */
    overflow: hidden;
}

.show-more .utl__button {
    display: none;
}
/*-----------------------------------------------------------*/

/*-----------------------------------------------------------*/
/* REGISTER PAGE                                             */
/*-----------------------------------------------------------*/

 .prefix-span {
     position: absolute;
     top: 51%;
     margin-left: 13%;
     left: 10px; /* Adjust the left position as needed */
     transform: translateY(-50%);
 }
[cb-input-type=phone-reea] .utl-ipt__field__wrapper:not(.country-select) .utl-ipt__field.contain-prefix {
    padding-left: 120px;
}

@media screen and (max-width: 767px) {
    .prefix-span {
        position: absolute;
        top: 51%;
        margin-left: 75px;
        left: 10px; /* Adjust the left position as needed */
        transform: translateY(-50%);
    }
    [cb-input-type=phone-reea] .utl-ipt__field__wrapper:not(.country-select) .utl-ipt__field.contain-prefix {
        padding-left: 119px;
    }
}

/*overwrite cb-input-type=phone-reea */
[cb-input-type=phone-reea] .country-select .utl-ipt__info__icon__wrapper.select {
    display: flex;
}
[cb-input-type=phone-reea] .country-select .utl-ipt__info__icon__wrapper.select .utl-ipt__info__icon {
    background-position: 50% -80px;
}
[cb-input-type=phone-reea] .utl-ipt__field__wrapper:not(.country-select) .utl-ipt__field {
    padding-left: 92px;
}
/*-----------------------------------------------------------*/
/* REGISTER PAGE                                              */
/*-----------------------------------------------------------*/
.modal_prod_box {
    padding-top: 25% !important;
    border-radius: 8px !important;
    text-align: center !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(239, 239, 239, 90%) !important;
    z-index: 999 !important;
}

.mozilla_input_type_number {
    -moz-appearance: textfield !important;
}

/*overwrite length search input filter*/
#w-node-_9cf0fb35-df88-b4cc-c9bd-2af798ed5f6a-98ed5f64 {
    grid-area: span 1 / span 2 / span 1 / span 1;
}

.flt-tgs-flex {
    flex-wrap: wrap;
}

@media (max-width: 1220px) {

    .hide_slot {
        display: none;
    }
}

/*display Locations Page START*/
.container_video {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.standorte__cards_custom{
    z-index: 10;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    position: relative;
}
@media screen and (max-width: 991px) {
    .standorte__cards_custom {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 600px) {
    .standorte__cards_custom {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
        grid-template-columns: 1fr;
    }
}
/*display Locations Page END*/

/*-----------------------------------------------------------*/
/* LOGO LINK PAGE                                            */
/*-----------------------------------------------------------*/

.utl__rich-text a.logo-link {
    color: inherit; 
    text-decoration: none !important; 
    cursor: pointer; 
    font-size: 20px;
}

.utl__rich-text a.logo-link:hover {
    color: black;
    text-decoration: none; 
}

.blg-featured--no-hero {
    margin-bottom: 3px; /* Add a bottom margin of 3px */
}

.responsive-width {
    width: calc(100vw - 15%); 
}

@media only screen and (max-width: 767px) {
    .lay__carousel .prd-itm__wrapper {
      min-width: 37vw !important;
    }
}

/*-----------------------------------------------------------*/
/* LOADING ICON                                             */
/*-----------------------------------------------------------*/

.loading-icon .loader {
    border: 4px solid #f3f3f3; 
    border-top: 4px solid #8B0000; 
    border-radius: 50%;
    width: 20px; 
    height: 20px; 
    animation: spin 2s linear infinite;
    margin-left: 10px; 
    vertical-align: middle; 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.utl__button.disabled {
    pointer-events: none;
    opacity: 0.6;
}

/*-----------------------------------------------------------*/
/* PRICE SUMMARY & OC                                        */
/*-----------------------------------------------------------*/

.prd-lst__price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.prd-lst__original-price p {
    text-decoration: line-through;
    color: #070707; 
    margin-right: 10px; 
    font-weight: 500;
}

.prd-lst__price {
    margin-left: auto;
}

.prd-lst__price .discount{
    color: #c50035;
}

.percentage-discount{
    color: #c50035;
}




/* COOKIE-POLICY BOT BANNER (FROM ESE) */

#CookiebotWidget,
#CookiebotWidget *,
#CybotCookiebotDialog,
#CybotCookiebotDialog * {
  font-family: Graphik, sans-serif !important;
}

#CybotCookiebotDialog label,
#CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonDescription,
#CybotCookiebotDialog h2,
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails a {
  font-weight: 400;
}
#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton,
#CookiebotWidget #CookiebotWidget-buttons button#CookiebotWidget-btn-change,
#CookiebotWidget #CookiebotWidget-buttons button#CookiebotWidget-btn-withdraw {
  border-radius: 100px;
  font-weight: 400;
}
#CybotCookiebotDialogHeader {
  display: none;
}
#CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentCookieContainerButton, #CybotCookiebotDialog .CookieCard .CybotCookiebotDialogDetailBodyContentIABv2Tab {
  display: flex;
}

