.bb-wishlist-button > span.text {
  @media screen and (max-width: $breakpoint-sm) {
    display: none !important;
  }
}

.bb-wishlist-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 12px 24px !important;
  margin: 0 !important;

  i {
    width: 35px !important;
  }
}

.bb-wishlist-button > span > i.heart {
  @media screen and (max-width: $breakpoint-sm) {
    margin: unset !important;
  }
}

.bb-add-to-cart-container {
  display: flex;
  gap: 1em;
  padding: 1em 0;
  
  > * {
    margin: 0 !important;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
  }
}
