.bb-wishlist {
    &-variant {
        display: grid;
        grid-gap: $spacer * 0.75;
        align-items: center;
        font-size: $font-size-sm;
        margin: 0;

        @media screen and (max-width: $breakpoint-md) {
            grid-template-columns: 1fr;
        }

        @media screen and (min-width: $breakpoint-md) {
            grid-template-columns: repeat(3, 1fr);
        }

        &-label {
            display: block;
            margin: 0 0 $spacer * 0.25 0;
        }

        &-select {
            font-weight: bold;
            font-size: $font-size-sm;
            line-height: 1;
        }
    }
}
