.bb-wishlist-list {
    margin: auto;
    background-color: #ffffff;
    padding: 1.5rem;
    border-radius: 0.28571429rem;
    border: 1px solid rgba(34, 36, 38, 0.15);

    &-wraper {
        display: flex;
        align-items: center;
        
        &-icon {
            aspect-ratio: 1/1;
            font-size: 1.5em;
            vertical-align: middle;
            margin-right: 2rem;            
        }

        &-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &-name {
                padding-left: 1rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-buttons {
                flex-shrink: 0;
            }
        }
    }
}

