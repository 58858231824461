$bright_color: #ffffff;
$dark_color: #050428;
$transparent_color: rgba(0, 0, 0, 0.25);

.bitbag {
    &.wishlist-modal-initialization {
        display: none;
    }

    &.wishlist-modal-initialized {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: $transparent_color;
    }

    .wishlist-confirmation-modal {
        background-color: $bright_color;
        border-radius: 8px;
        padding: 24px;

        &__header,
        &__body,
        &__confirm {
            padding: 16px 0;
            text-align: center;
            gap: 1rem;
        }

        &__header {
            &--title {
                font-size: 24px;
            }
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;

            &--input {
                width: min-content;
                padding-top: 1rem;
                line-height: 1.4285em;
                color: rgba(0,0,0,.87);
                font-weight: 400;
                -webkit-text-size-adjust: 100%;
                border-color: #85b7d9;
                text-align: left;
                padding: .67857143em 1em;
                border: 1px solid rgba(34,36,38,.15);
                border-radius: .28571429rem;
                -webkit-tap-highlight-color: rgba(255,255,255,0);
            }

            &--input::placeholder {
                max-height: auto;
            }

            &--input.error {
                background-color: #FFF6F6;
                border-color: #E0B4B4;
                color: #9F3A38;
            }

            .validation-error {
                width: min-content;
                white-space: nowrap;
            }

            .form-body {
                display: flex;
            }

        }

        &__confirm {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            &--cancel,
            &--perform {
                cursor: pointer;
                padding: 12px 8px;
                text-align: center;
                min-width: 125px;
                transition: opacity 0.3s ease;
                border-radius: 4px;
                border: none;
                font-weight: 650;
                letter-spacing: 1.25px;
                font-size: 14px;
                text-transform: capitalize;

                &:hover {
                    opacity: 0.7;
                }
            }

            &--cancel {
                color: $dark_color;
                border: 1px solid $dark_color;
                background-color: $bright_color;

                &:focus {
                    background-color: darken($bright_color, 20%);
                }
            }

            &--perform {
                color: $bright_color;
                border: 1px solid $dark_color;
                background-color: $dark_color;

                &:focus {
                    background-color: lighten($dark_color, 20%);
                }
            }
        }
    }
}

.wishlist-confirmation-modal__body--item {
    padding-bottom: 1rem;
    font-size: 16px;
}
